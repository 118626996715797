import Head from 'next/head'
import { useAmp } from 'next/amp'
import { stringToId } from '../../../helpers/stringToId'
import { IPictureElement } from '../../../types/pictures'

export interface IPreloadPictures {
  elementList?: IPictureElement[]
}

export function PreloadPictures({ elementList }: IPreloadPictures): JSX.Element {
  const isAmp = useAmp()

  return (
    <Head>
      {elementList?.map(({ url, media, type }) => (
        <link
          key={stringToId(url)}
          rel="preload"
          as="image"
          href={url}
          type={type}
          media={
            media.max
              ? `(min-width: ${media.min}px) and (max-width: ${media.max}px)`
              : `(min-width: ${media.min}px)`
          }
          fetchPriority={isAmp ? undefined : 'high'}
        />
      ))}
    </Head>
  )
}
