import { SVGIcon } from '../../SVGIcon'

export interface IPlaceholderLogo {
  size?: number
  theme?: 'dark' | 'light'
}

export function PlaceholderLogo({ size = 191, theme = 'dark' }: IPlaceholderLogo): JSX.Element {
  const height = `${size * (154 / 191)}px`
  return (
    <SVGIcon
      name="placeholder-logo"
      className={`PlaceholderLogo-${theme}`}
      width={`${size}px`}
      height={height}
      data-testid="placeholder"
    />
  )
}
